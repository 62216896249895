@import url('https://fonts.googleapis.com/css2?family=Dancing+Script&family=Source+Sans+Pro&display=swap');

.App {
  /* height: 100vh;  */
  font-family: 'Source Sans Pro', sans-serif;
}

/* @media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
} */

/* @keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */
