@import url('https://fonts.googleapis.com/css2?family=Dancing+Script&display=swap');

.landing, .mobile-landing {
    text-align: center;
    padding-bottom: 20px; 
    height: 100%; 
}

.landing::after, .mobile-landing::after {
    content: "";
    background: url('./photos/memphis.png');
    opacity: 0.05;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: -1;   
}

.landing .slider {
    padding: 20px 0; 
}

.landing .slider h1 {
    display: inline; 
} 

.circle {
    height: 300px; 
    width: 300px; 
    border-radius: 50%;
    background-color: pink; 
    margin: 0 auto; 
    display: flex; 
    align-items: center;
    justify-content: center;
    transition-duration: 1s;
    transition-timing-function: ease-in;
    margin-top: 25px; 
    margin-bottom: 25px; 
}

.circle:hover {
    height: 450px; 
    width: 450px; 
    background-color: aquamarine;
}

.circle a {
    text-decoration: none;
    color: black; 
}

.circle a span {
    font-family: 'Dancing Script', cursive;
    font-size: 30px; 
}

.circle a:hover {
    text-decoration: underline;
}

.circle a:visited {
    color: black; 
}

.landing h2 {
    padding-top: 20px; 
}

/* mobile */

.mobile-circle {
    height: 300px; 
    width: 300px; 
    border-radius: 50%;
    background-color: aquamarine; 
    margin: 0 auto; 
    display: flex; 
    align-items: center;
    justify-content: center;
    /* transition-duration: 1s;
    transition-timing-function: ease-in; */
    margin-top: 25px; 
    margin-bottom: 25px; 
}

.mobile-circle a span {
    font-family: 'Dancing Script', cursive;
    font-size: 30px; 
}

.mobile-circle a {
    color: black; 
}

.mobile-circle a:visited {
    color: black; 
}