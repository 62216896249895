.home {
    text-align: center;
}

.nav {
    display: flex;
    justify-content: space-around;
    margin: 0 auto; 
    width: 60%; 
}

.nav a {
    cursor: pointer;
}

.nav a:hover {
    text-decoration: underline;
}

.image {
    margin: 0 auto; 
    margin-top: 40px;
    width: 55%; 
}

.image img {
    width: 100%; 
    height: auto;  
}

#bio h1, #projects h1, #connect h1 {
    font-size: 28px; 
}

#bio {
    border-bottom: solid; 
    border-width: 0.5px; 
    padding-bottom: 20px; 
}

#bio p {
    width: 70%; 
    margin: 0 auto; 
    line-height: 30px; 
}

#projects .project {
    border-bottom: solid; 
    border-width: 0.5px; 
    width: 80%; 
    margin: 0 auto; 
    padding-bottom: 20px; 
}

#projects .project h3 {
    padding: 10px; 
}

#projects .project img {
    width: 70%; 
    height: auto;  
    border: solid; 
    border-width: 0.8px; 
}

#projects .project .link {
    padding: 12px; 
}

#projects .project .link h5 {
    margin-right: 5px; 
}

#projects .project .link h5, #projects .project .link a {
    display: inline; 
}

#projects .project p {
    width: 70%; 
    margin: 0 auto; 
    line-height: 30px; 
}

#connect {
    padding-bottom: 50px; 
}

#connect .connect-container div {
    margin: 0 auto; 
    width: 50%; 
    border: solid; 
    border-width: 0.5px; 
}

#connect .connect-container div h4 {
    color: white; 
}

#connect .connect-container .email {
    background-color: #92E6A7
}

#connect .connect-container .linkedin {
    background-color: #6EDE8A
}

#connect .connect-container .github {
    background-color: #4AD66D
}

#connect .connect-container .spotify {
    background-color: #2DC653
}

/* media query for mobile */

@media all and (max-width: 420px) {
    .nav {
        width: 90%; 
    }

    .image {
        width: 100%;
    }

    #projects .project {
        width: 100%; 
    }

    #connect .connect-container div {
        width: 80%; 
    }
}